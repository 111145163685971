import React from 'react'
export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()
// export const GlobalLocationTransmiter = React.createContext()

const initialState = {
  menu: false,
  language: '',
  
  catFilters: {
    common: [],
    type: [],
    purpose: [],
    category: [],
  },  
  activeFilters: [],
  activeTypeFilters: [],
  activePurposeFilters: [],
  activeCategoryFilters: [],

  // /////////
  // filtred: {},
  // countByFilter: {},
  // /////////

  searchInputAutoComp: false,
  sideNavUse: 'n1',
}

function reducer(state, action) {
  switch (action.type) {

    case 'TOGGLE_MENU': {
      return {
        ...state,
        menu: !state.menu,
      }
    }

    case 'CURRENT_LANGUAGE': {
      return {
        ...state,
        language: action.value
      }
    }

    case 'CAT_FILTERS': {
      return {
        ...state,
        catFilters: action.value
      }
    }
    case 'ACTIVE_FILTERS': {
      return {
        ...state,
        activeFilters: action.value
      }
    }
    case 'ACTIVE_TYPE_FILTERS': {
      return {
        ...state,
        activeTypeFilters: action.value
      }
    }
    case 'ACTIVE_PURPOSE_FILTERS': {
      return {
        ...state,
        activePurposeFilters: action.value
      }
    }
    case 'ACTIVE_CATEGORY_FILTERS': {
      return {
        ...state,
        activeCategoryFilters: action.value
      }
    }
    
    ////////
    // case 'FILTRED_BY_FILTERS': {
    //   return {
    //     ...state,
    //     filtred: action.value
    //   }
    // }

    // case 'COUNT_BY_FILTERS': {
    //   return {
    //     ...state,
    //     countByFilter: action.value
    //   }
    // }
    ////////

    case 'SIDENAV_2USE': {
      return {
        ...state,
        sideNavUse: action.value
      }
    }

    case 'SEARCH_AUTOCOMPLETE': {
      return {
        ...state,
        searchInputAutoComp: action.value
      }
    }


  default:
      throw new Error('Bad Action Type')
  }
}

// const GlobalContextProvider = ({ children, dataBrowser }) => {
const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)


  // console.log(dataBrowser)

  // const [browserCtx, setBrowserCtx] = React.useState( dataBrowser ? dataBrowser : {} )

  
  
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>

        {/* <GlobalLocationTransmiter.Provider value={browserCtx}> */}

          {children}

        {/* </GlobalLocationTransmiter.Provider> */}

      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}
export default GlobalContextProvider