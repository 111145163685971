import React from 'react'
import GlobalContextProvider from './src/context/GlobalContextProvider'


// LOCATION DATA CAPTURE
// const browserLoco = {}
// export const onRouteUpdate = ({ location, prevLocation }) => {
//   browserLoco.prevLoco = prevLocation
//   browserLoco.loco = location
// }

// export const onClientEntry = () => {

//   const preloader = document.createElement('div')
//   preloader.classList.add('initialPreLoader')
//   preloader.style.position = 'fixed'
//   preloader.style.width = '100vw'
//   preloader.style.height = '100vh'
//   preloader.style.top = '0'
//   preloader.style.left = '0'
//   preloader.style.backgroundColor = '#000'
//   preloader.style.zIndex = '10000'

//   document.body.append(preloader)  


//   window.addEventListener('load', () => {
//     setTimeout(preloader.remove(), 1500)
//   })

//   // console.log('PRELOADER START')
// }


export const wrapRootElement = ({ element }) => {
  // return <GlobalContextProvider dataBrowser={browserLoco} >{element}</GlobalContextProvider>
  return <GlobalContextProvider>{element}</GlobalContextProvider>
}


// export const onServiceWorkerUpdateReady = () => {
//   window.location.reload();
// }



  //////////////////////////////////////////////////
 //////       ERROR #gatsby-source-wordpress_111002
//////////////////////////////////////////////////

